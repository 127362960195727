html {
    width: 100%;
    height: 100%;
    font-size: 14px;
}

body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

/*create .hidden */
.hidden {
    display: none !important;
  }
/* create missing btn-xs from bootstrap 3*/
  .btn-xs
  {
      padding: 1px 5px !important;
      font-size: 12px !important;
      line-height: 1.5 !important;
      border-radius: 3px !important;
  }
  /*create missing btn group justified*/
  .btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
 }
    .btn-group-justified .btn,
    .btn-group-justified .btn-group {
      float: none;
      display: table-cell;
      width: 1%;
     }
      .btn-group-justified .btn .btn,
      .btn-group-justified .btn-group .btn {
        width: 100%; 
    }
      .btn-group-justified .btn .dropdown-menu,
      .btn-group-justified .btn-group .dropdown-menu {
        left: auto; 
    }
/* */
.form-control-feedback {
    position: absolute;
    top: .75rem;
    right: 0.5rem;
    z-index: 2;
    display: block;   
    text-align: center;
    pointer-events: none;
}
/**/
.remove-caret::after {
    display: none !important;
}

.ng-valid[required], .ng-valid.required  {
  /*border-left: 2px solid #42A948;  green */
}

.ng-invalid:not(form)  {
  border-left: 2px solid #a94442; /* red */
}

.navbar {
    margin-bottom: 0;
}

.container-full {

    min-width: 320px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;
}

.map {
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    z-index: 999;
    top: 10px;
    left: 45px;
    /* padding: 2px; */
    background-color: #2e353d;
    border-radius: 3px;
}

.info-popover{
    min-width: 100px;
    max-width: 350px;
}
.info-popover-tranz {
    min-width: 100px;
    max-width: 250px;
}
.popover-content{
    white-space: pre-line;
}
.ol-popup-content{
    padding:0px !important;
}

/*popup info */
.popupFeatureInfo {
    min-height: 50px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: auto;
}

.popupFeaturesListInfo {
    min-height: 100px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: auto;
    margin-bottom: 5px;
}

.ol-zoom {
    top: 4.5em !important;
    left: .5em;
}

.position-button {
    top: 9em;
    left: .5em;
}

.position-button-on {
    background-color: aqua;
}

.position-button-on button {
    color: aqua;
}

.select-button {
    top: 12em;
    left: .5em;
}

.select-button-on {
    background-color: aqua;
}
.select-button-off {
    background-color: red;
}

.select-button-on button {
    color: aqua;
}

.tool-button-end {
    background-color: darkorange;
}

.measure-distance-button {
    top: 16em;
    left: .5em;
}

.measure-area-button {
    top: 18em;
    left: .5em;
}

.one-active-layer-button {
    top: 21em;
    left: .5em;
}

.autorefresh-button {
    top: 24em;
    left: .5em;
}


.tranzit-button {
    top: 27em;
    left: .5em;
}

.greentop-download-button {
    top: 29em;
    left: .5em;
}
.greentop-training-button {
    top: 31em;
    left: .5em;
}
.greentop-classify-button {
    top: 33em;
    left: .5em;
}
.integrate-landsat-download-button {
    top: 37em;
    left: .5em;
}
.integrate-landsat-download-button {
    top: 37em;
    left: .5em;
}
.integrate-landsat-hotspot-button {
    top: 39em;
    left: .5em;
}
.integrate-landsat-prindconex-button {
    top: 41em;
    left: .5em;
}
.integrate-landsat-prdtsez-button {
    top: 43em;
    left: .5em;
}
.autorefres-button-hide {
    display: none !important;
}

.legend-button {
    bottom: 5em;
    right: .5em;
}

.legend-button-on {
    background-color: aqua;
}

.legend-button-on button {
    color: aqua;
}



/*legent style*/

/* */

.ol-mouse-position {
    top: unset;
    bottom: 8px;
    right: 8px;
    position: absolute;
}

.ol-attribution {
    text-align: right;
    bottom: 2.5em;
    right: .5em;
    max-width: calc(100% - 1.3em);
}



/* */

#pos-input-button-group .toggle-btn {
    display: block;
    padding: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
}

#pos-input-button-group .input-group-btn {
    position: fixed;
    top: 12px;
    left: 12px;
    padding-top: 1px;
    padding-bottom: 5px;
}

/* feature image dialog with to 100%*/
.modalimgview .modal-dialog {
    max-width: 100% !important;
}

.modalFeaturesDetailsView .modal-dialog {
    max-width: 100% !important;
}
.modalFeaturesPrintView .modal-dialog {
    max-width: 290mm !important;
}

.modalFisaSpatiuVerde .modal-dialog {
    max-width: 290mm !important;
}
.modalInfoClickGraph .modal-dialog {
    max-width: 100vw !important;
}
.modalInfoClick3DGraph .modal-dialog {
    max-width: 100vw !important;
}

.modalSettingsMain {
    background-color: #102f51d9;
}

.modalUserMain {
    background-color: #102f51d9;
}




/* sub menu style todo*/

.sub-menu .sub-menu-item p{
    margin: 1px 1px 1px 1px;
    min-height: 20px;
}

.sub-menu .sub-menu-item .sub-menu-inputs,
.sub-menu .sub-menu-item .sub-menu-inputs #inputs-col-1,
.sub-menu .sub-menu-item .sub-menu-inputs #inputs-col-2 ,
.sub-menu .sub-menu-item .sub-menu-inputs #inputs-col-3 {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    height: 30px;
}
.sub-menu .sub-menu-item .sub-menu-inputs #inputs-col-2 {
    padding: 0px 10px 0px 0px;     
}
.sub-menu .sub-menu-item .sub-menu-inputs #inputs-col-3 {
    padding: 0px 0px 0px 0px;
    text-align:right;
}

.sub-menu .sub-menu-item .sub-menu-inputs #inputs-col-3 .dropdown-toggle-menu {
    padding: 0px 10px 0px 0px;
}

.sub-menu .sub-menu-item .sub-menu-inputs #inputs-col-3 li {
    padding: 2px 10px 2px 5px;
    background-color:  cornflowerblue;
    color: white;
    text-align: right;
}

.sub-menu .sub-menu-item .sub-menu-inputs #inputs-col-3 li:hover {
    background-color: royalblue;
}
.sub-menu-inputs input[type=checkbox] {
    margin: 1px 1px 1px 1px;
    vertical-align: middle;
}
.sub-menu-sld-script {
    margin: 1px
}

/* */
.ngdialog.ngdialog-theme-default .ngdialog-content {
    padding: 0;
}

.cmbanimrasterend .ng-select-container .ng-value-container {
    color:crimson !important;
}
.cmbanimrasterst .ng-select-container .ng-value-container {
    color:aquamarine !important;
}

.info_wrapper {
    position: fixed;
    top: 0;
    z-index: 500;
    width: 100%;
    margin-top: 1%;
    display: flex;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    flex-wrap: wrap;
    align-items: center;
    padding: 1%;
}

.button_wrapper {
    position: fixed;
    bottom: 0;
    z-index: 500;
    width: 100%;
    margin-bottom: 1%;
    display: flex;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.3);
    flex-wrap: wrap;
    align-items: center;
}

.button_wrapper > .btn {
    margin: 1%;
}

    .button_wrapper > .form-group {
        margin: 1%;
    }


/* */
.select2 > .select2-choice.ui-select-match {
    /* Because of the inclusion of Bootstrap */
    height: 29px;
}

/*.selectize-control > .selectize-dropdown {
    top: 36px;
}*/
/* Some additional styling to demonstrate that append-to-body helps achieve the proper z-index layering. */
/*.select-box {
    background: #fff;
    position: relative;
    z-index: 1;
}*/

/*.alert-info.positioned {
    margin-top: 1em;
    position: relative;
    z-index: 10000;*/ /* The select2 dropdown has a z-index of 9999 */
/*}
*/
/* */
/* Align Selectize with input-group-btn */
/*.selectize-control {
    top: 2px;
}*/

    /*.selectize-control > .selectize-dropdown {
        top: 34px;
    }*/

/* Reset right rounded corners, see Bootstrap input-groups.less */
.input-group > .selectize-control > .selectize-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}

.dark-tab-heading {
    color: #e1ffff;
}
 .nav-tabs .nav-link.active .dark-tab-heading {
    color:#1a1d1d !important;
}

/*tranzit menu*/



/*position menu*/


/*info feature*/
.info-images-img {
    width:90vw;
    height: 80vh; 
    object-fit: contain;
    background-image: url(/img/loading-image-background.jpg);
    background-size: unset;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.selectize-dropdown .active {
    background-color: rgb(197, 232, 248) !important;
}
/*fix for layer menu not visible*/
.nav-side-menu .nav-side-tree tree-node-children .tree-children {
    overflow: visible!important;
}
.nav-side-menu tree-viewport {
    overflow: visible !important;
}

/* greentop common*/

.greentop-action-buttons{
    font-size: .8em;
}
.greentop-action-wrp-buttons{
    padding: 2px;
    margin: 2px;
}
.label-full-width{
    width: 100%;
}
.greentop-title-bar{
    display:flex;
    padding: 1px;
}
.greentop-title-bar-fill {
    flex:1;
    text-overflow: clip;
}
.greentop-config-tab {
    font-family: verdana;
    font-size: 12px;
    font-weight: 200;
    /* background-color: #2e353d; */
    color: #e1ffff;


}
.greentop-button-hide{
    display: none !important;
}
.integrate-landsat-button-hide{
    display: none !important;
}
.integrate-dw-btn-dark{
    color:#333;
}

.greentop-ng-select-wrap {
    white-space: normal;
}
.integrate-ng-select-wrap {
    white-space: normal;
}
.ng-select-wrap {
    white-space: normal;
}
.tranz-button-hide{
    display: none !important;
}
/*  */
.tool-spiner-box{
    text-align: center;
}
.tool-spiner{
    font-size:24px;
}



.textarea-noresize {
    resize: none !important;
}
.animate-raster-existing-mds-combo{
    min-width: 250px;
}



/*layer menu settings in style*/
.layerslider.mat-slider-disabled .mat-slider-track-background,
 .layerslider.mat-slider-disabled .mat-slider-track-fill,
  .layerslider.mat-slider-disabled .mat-slider-thumb {
    background-color: rgb(121 109 109 / 60%) !important; 
}
.layerslider.mat-slider-horizontal {
    height: 28px;
    min-width: 128px;
}
.layerslider.mat-slider-horizontal .mat-slider-wrapper {
    height: 2px;
    top: 13px;
    left: 8px;
    right: 8px;
}
.layerslider.mat-slider-horizontal .mat-slider-track-background {
    background-color: rgb(55 52 52 / 66%);
}
.layerslider.mat-primary .mat-slider-track-fill,
.layerslider.mat-primary .mat-slider-thumb,
.layerslider.mat-primary .mat-slider-thumb-label {
    background-color: #cbc4d5;
}

ngb-rating {
    color: #f3861f;
    font-size: 1.5em;
}

.rating-container .filled-stars {
    color: #f3861f;
}

/*print details report grid*/
.gridPrintOverflow {
    overflow-x:scroll !important;
}


/*media settings*/
@media (max-width: 768px){
    .tranz-options .nav-justified > li {
        display: table-cell;
        width: 1%;
  }
  .tranz-options .nav-justified > li > a  {
    border-bottom: 1px solid #ddd !important;
    border-radius: 4px 4px 0 0 !important;
    margin-bottom: 0 !important;
}
.tranz-options .nav-justified > li.active > a  {
    border-bottom: 1px solid transparent !important;
}

.horizontal-tabs-only .nav-justified > li {
    display: table-cell;
    width: 1%;
}
.horizontal-tabs-only .nav-justified > li > a  {
    border-bottom: 1px solid #ddd !important;
    border-radius: 4px 4px 0 0 !important;
    margin-bottom: 0 !important;
}
.horizontal-tabs-only .nav-justified > li.active > a  {
    border-bottom: 1px solid transparent !important;
}

}

@media (max-width: 360px){
    .overlay {
        right: 5px;
    }
    .tranzit-menu {
        min-width: 250px;
    }
    .position-menu {
        min-width: 250px;
    }
    .modalEditSearchInfo .modal-dialog {
        max-width: 100vw !important;
        margin-left: 0;
        margin-right: 0;
    }
}


@media (max-width: 767px) {
    .overlay {
        width: auto;
        margin-right: 5px;
    }
    .tranzit-menu-height {
        max-height: 50vh;
        overflow-y: auto;
    }
    
    /* Mobile modal fix*/
    .modal {
        position: fixed;
        /* top: 3%; */
        right: 0;
        left: 0;
        width: 100%;
        margin: 0;
    }

    .modal-body {
        height: 80%;
        /*max-height: 550px;*/
        padding: 15px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}
@media (min-width: 361px) {
    .tranzit-menu {
        min-width: 290px;
    }
    .position-menu {
        min-width: 290px;
    }
}

@media (min-width: 767px) {
    .nav-side-menu .menu-list .menu-content {
        /*display: block;*/
    }
}


@media print {
    body.ngdialog-open {
        height: auto;
        overflow: scroll;
    }

    .modal-open{
        overflow: visible !important;
    }

    .modal {
    padding-bottom: 0px;
    padding-top: 0px;
    position: relative;
    height:auto;
    }

    body.modal-open {
        height: auto;
        overflow: scroll;
    }

    body.modal-open div.container-full {
        display: none !important;
        /*height: 0px !important;*/
    }

    /*body {
        height: 200%;
    }*/
    div#printFeaturesInfo div.mapImageContainer {
        page-break-after: always;
    }
    div#printFeaturesInfo div.listOfTables {
        page-break-after: always;
    }
    .pageBrakeAfter {
        page-break-after: always;
    }
    .gridPrintBlock {
        display: block !important;
    }
    .gridPrintOverflow {
        overflow-x:scroll;
    }
    .modalFeaturesPrintView .modal-dialog {
        max-width: 100vw !important; 
    }
    .modalFisaSpatiuVerde .modal-dialog {
        max-width: 100vw !important;
    }
    .modalInfoClickGraph .modal-dialog {
        max-width: 100vw !important;
    }
    .modalInfoClick3DGraph .modal-dialog {
        max-width: 100vw !important;
    }
    
    
}

ngb-typeahead-window {
    z-index: 10110 !important;
}

.tooltip-a {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
}

.tooltip-a-measure {
    opacity: 1;
    font-weight: bold;
}

.tooltip-a-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;
}

    .tooltip-a-measure:before,
    .tooltip-a-static:before {
        border-top: 6px solid rgba(0, 0, 0, 0.5);
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        content: "";
        position: absolute;
        bottom: -6px;
        margin-left: -7px;
        left: 50%;
    }

    .tooltip-a-static:before {
        border-top-color: #ffcc33;
    } 
    
    .margin-zero {
        margin:0px 0px 0px 0px !important;
    }
    .padding-left-zero{
        padding-left: 0px !important;
    }
    .padding-right-zero{
        padding-right: 0px !important;
    }
.text-shadow-map {
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.ol-control button {
    display: block;
    margin: 1px;
    padding: 0;
    color: #fff;
    font-size: 1.14em;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    height: 1.375em;
    width: 1.375em;
    line-height: .4em;
    background-color: rgba(0,60,136,.5);
    border: none;
    border-radius: 2px
}